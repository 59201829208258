import { observer } from "mobx-react-lite"
import { Outlet } from "@tanstack/react-router"
import { useAuth } from "@/stores/Auth"
import { IconDoorExit, IconUserCircle } from "@tabler/icons-react"
import { Button } from "@/components/Button"
import { Icon } from "@/components/Icon"
import { Text } from "react-aria-components"

interface SideBarProps {
    children?: React.ReactElement | React.ReactElement[]
}

export const SideBar = observer(({children}: SideBarProps) => {
    const auth = useAuth()
    return (
        <div className='h-full shrink-0 basis-[350px] max-w-[500px] flex flex-col justify-between grow-[0.20] bg-slate-800 border-r border-r-slate-500 text-white overflow-y-auto overflow-x-hidden scroller'>
            <div>
                {children ? children : <Outlet/>}
            </div>
            {auth.user &&
            <div className="pl-4">
                <div className="flex items-center">
                    <IconUserCircle size={80} stroke={1}></IconUserCircle>
                    <div className="flex flex-col gap-2">
                        <div>
                            <div className="text-xl text-slate-300">{auth.user?.username}</div>
                            <a className="text-lg text-teal-500" href={auth.user?.selectedSite.url} target="_blank">{auth.user?.selectedSite.url}</a>
                        </div>
                        <Button onPress={() => auth.logout()}>
                            <Icon icon={IconDoorExit}/>
                            <Text>Sign Out</Text>
                        </Button>
                    </div>
                </div>
                <div className="h-[60px] shrink-0"/>
            </div>}
        </div>
    )
})